import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Row,
  Typography,
  Select,
  Space,
  Tooltip,
  message,
  Collapse,
  Empty,
  Checkbox,
  Divider,
  InputNumber,
} from 'antd';
import { useSelector } from 'react-redux'
import { IDataSheet } from 'src/components/IDataSheet'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { getDropdownFilteredValue } from 'src/utils/decorator'
import useTranslate from 'src/utils/useTranslate'
import './inputConstraints.scss'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyledCard } from 'src/styled_components/StyledCard'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useValue } from 'src/utils/useValue'
import { antdTheme } from 'src/constants'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'

const EXCEPTIONS = {
  mustIncludes: {
    categories: ['Solvents', 'Reactants'],
    parameters: ['water', 'substrate']
  }
}

const { Text } = Typography

const { Option } = Select

export const InputConstraints = ({
  parameterList,
  setParameterList,
  categoricalRange,
  categorialInputList,
  setCategorialInputList,
  catwiseParameterList,
  setCatwiseParameterList,
  modelData,
  setUnsavedChanges,
  range,
  inputContraintsData,
  currentSelectedStage,
  catConstraintsList,
  setCatConstraintsList,
  selectAllIngredientsInCategory,
  setSelectAllIngredientsInCategory,
  exludeAllParameterConstraints,
  setExludeAllParameterConstraints,
  exludeAllCategoryConstraints,
  setExludeAllCategoryConstraints,
  isCrossCategorical,
  setIsCrossCategorical,
  setIsCrossCategoricalModalVisible,
  isMultiStage,
  isCategoricalConstraintsUseDefault,
  setIsCategoricalConstraintsUseDefault
}: any) => {
  const { convertValue, getValue: getEUValue, formatter, parser } = useValue()

  const [t] = useTranslate()
  const labels = useSelector((state: StoreState) => state.displayNames.data || {})

  const INCLUSION_MODES = useMemo(
    () => [
      {
        value: 'default',
        label: t('common.canInclude')
      },
      {
        value: 'include',
        label: t('common.mustInclude')
      },
      {
        value: 'exclude',
        label: t('common.exclude')
      }
    ],
    [t]
  )

  const addRow = useCallback(
    (type: string, category: string, catIndex: number, subtype: string) => {
      if (type === 'ingredients') {
        if (subtype === 'numerical') {
          setParameterList((prevState: any) => {
            const newState = JSON.parse(JSON.stringify(prevState))
            newState[currentSelectedStage]?.push({
              parameter: '',
              exclude: exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked ?? false,
              include: false,
              min: null,
              max: null,
              type,
              category,
              catIndex
            })
            return { ...newState }
          })
        }
        if (subtype === 'categorical') {
          setCategorialInputList((prevState: any) => {
            const newState = JSON.parse(JSON.stringify(prevState))
            newState[currentSelectedStage]?.push({
              parameter: '',
              data: [],
              category,
              catIndex,
              type
            })
            return { ...newState }
          })
        }
      }
      if (type === 'processing') {
        if (subtype === 'numerical') {
          setParameterList((prevState: any) => {
            const newState = JSON.parse(JSON.stringify(prevState))
            newState[currentSelectedStage]?.push({
              parameter: '',
              exclude: false,
              include: false,
              min: null,
              max: null,
              type,
              category,
              catIndex
            })
            return { ...newState }
          })
        }
        if (subtype === 'categorical') {
          setCategorialInputList((prevState: any) => {
            const newState = JSON.parse(JSON.stringify(prevState))
            newState[currentSelectedStage]?.push({
              parameter: '',
              data: [],
              category,
              catIndex,
              type
            })
            return { ...newState }
          })
        }
      }
    },
    [setParameterList, currentSelectedStage, setCategorialInputList, exludeAllParameterConstraints?.numerical_parameter]
  )

  const removeRow = useCallback(
    (parameterIndex: any, subtype, category, parameter) => {
      if (subtype === 'numerical') {
        setParameterList((prevState: any) => {
          const newState = JSON.parse(JSON.stringify(prevState))
          newState[currentSelectedStage] = newState[currentSelectedStage].filter((item: any, index: number) => index !== parameterIndex)

          // Reseting first few parameter inclusion modes from exclude to can if they exceed 
          // the limit of min cat constraints that was just changed
          catConstraintsList?.filter((catObj: any) => catObj.constraint === 'count_of_category_items')?.forEach((catObj: any) => {
            let nonExcludedParams = newState?.[currentSelectedStage]?.filter((parameter: any) => parameter.category === catObj.category && !parameter.exclude)?.length
            const minNeededParams = catObj?.min || 1
            newState?.[currentSelectedStage]?.forEach((parameter: any, index: any) => {
              if (parameter.category === catObj.category) {
                if (nonExcludedParams < minNeededParams && parameter.exclude) {
                  newState[currentSelectedStage][index].exclude = false
                  newState[currentSelectedStage][index].include = false
                  nonExcludedParams++
                }
              }
            })
          })

          return { ...newState }
        })

        setSelectAllIngredientsInCategory((prevState: any) => {
          const newState = JSON.parse(JSON.stringify(prevState))
          newState[currentSelectedStage] = {
            ...newState[currentSelectedStage],
            [subtype]: {
              ...newState[currentSelectedStage]?.[subtype],
              [category]: !!newState[currentSelectedStage]?.[subtype]?.[category]?.length
                ? newState[currentSelectedStage]?.[subtype]?.[category].filter((item: any, index: number) => item !== parameter)
                : []
            }
          }
          return newState
        })

        setExludeAllParameterConstraints((prevState: any) => {
          const newState = JSON.parse(JSON.stringify(prevState))
          const catergoryData =
            newState[currentSelectedStage]?.numerical_parameter?.[category]?.data?.filter((categoryItem: string) => categoryItem !== parameter) ?? []

          newState[currentSelectedStage] = {
            ...newState[currentSelectedStage],
            numerical_parameter: {
              ...newState[currentSelectedStage]?.numerical_parameter,
              [category]: {
                ...newState[currentSelectedStage]?.numerical_parameter?.[category],
                isChecked: !catergoryData.length ? false : newState[currentSelectedStage]?.numerical_parameter?.[category]?.isChecked ?? false,
                data: catergoryData
              }
            }
          }
          return newState
        }, [])
      }
      if (subtype === 'categorical') {
        setCategorialInputList((prevState: any) => {
          const newState = JSON.parse(JSON.stringify(prevState))
          newState[currentSelectedStage] = newState[currentSelectedStage].filter((item: any, index: number) => index !== parameterIndex)
          return { ...newState }
        })
      }
    },
    [setParameterList, setSelectAllIngredientsInCategory, setExludeAllParameterConstraints, currentSelectedStage, catConstraintsList, setCategorialInputList]
  )

  const categoryListDropDown = useCallback((catIndex: any, type: string, category: string) => {
    return (
      <div style={{ width: '100%', padding: 16 }}>
        <Typography.Text
          style={{
            textAlign: 'left',
            width: '150px',
            maxWidth: '150px',
            whiteSpace: 'nowrap',
            verticalAlign: 'bottom',
            display: 'block'
          }}
          ellipsis={{
            tooltip: category
          }}
        >
          {category}
        </Typography.Text>
      </div>
    )
  }, [])

  const ingredientsDropdownOptionsNumerical = useCallback(
    (category) => {
      let ingredientsNames = modelData?.ingredients
        ? Object.keys(modelData?.ingredients)
        : modelData?.inputs_ingredients
          ? modelData?.inputs_ingredients
          : []
      const collator = new Intl.Collator([], { numeric: true })

      return Object.entries(inputContraintsData || {})
        ?.filter(([key, value]: any) => !parameterList?.map((row: any) => row?.parameter).includes(key) && !Array.isArray(value))
        .filter(([key]: any) => ingredientsNames?.includes(key))
        .filter(([key]: any) => modelData?.categories?.['ingredients']?.[key]?.includes(category)
        )
        .map(([key, value]: any) => ({
          value: key,
          label: modelData?.display_names?.ingredients?.[key] ?? labels?.ingredients?.[key]?.name ?? labels?.processing?.[key]?.name ?? key,
        }))
        .sort((a, b) => collator.compare(a.value, b.value))
    },
    [
      modelData?.ingredients,
      modelData?.inputs_ingredients,
      modelData?.categories,
      modelData?.display_names?.ingredients,
      inputContraintsData,
      parameterList,
      labels?.ingredients,
      labels?.processing
    ]
  )

  const ingredientsDropdownOptionsCategorical = useCallback(
    (category) => {
      let ingredientsNames = modelData?.ingredients
        ? Object.keys(modelData?.ingredients)
        : modelData?.inputs_ingredients
          ? modelData?.inputs_ingredients
          : []
      const collator = new Intl.Collator([], { numeric: false })

      return Object.entries(inputContraintsData || {})
        ?.filter(([key, value]: any) => !categorialInputList?.map((row: any) => row?.parameter).includes(key) && Array.isArray(value))
        .filter(([key]: any) => ingredientsNames?.includes(key))
        .filter(([key]: any) => modelData?.categories?.['ingredients']?.[key]?.includes(category)
        )
        .map(([key, value]: any) => ({
          value: key,
          label: modelData?.display_names?.ingredients?.[key] ?? labels?.ingredients?.[key]?.name ?? labels?.processing?.[key]?.name ?? key,
        }))
        .sort((a, b) => collator.compare(a.value, b.value))
    },
    [
      modelData?.ingredients,
      modelData?.inputs_ingredients,
      modelData?.categories,
      modelData?.display_names?.ingredients,
      inputContraintsData,
      categorialInputList,
      labels?.ingredients,
      labels?.processing
    ]
  )

  const processingDropdownOptionsNumerical = useCallback(
    (category) => {
      let processingNames = modelData?.processing
        ? Object.keys(modelData?.processing)
        : modelData?.inputs_processing
          ? modelData?.inputs_processing
          : []
      const collator = new Intl.Collator([], { numeric: true })

      return Object.entries(inputContraintsData || {})
        ?.filter(([key, value]: any) => !parameterList?.map((row: any) => row?.parameter).includes(key) && !Array.isArray(value))
        .filter(([key]: any) => processingNames?.includes(key))
        .filter(([key]: any) => modelData?.categories?.['processing']?.[key]?.includes(category)
        )
        .map(([key, value]: any) => ({
          value: key,
          label: labels?.processing?.[key]?.name ?? labels?.processing?.[key]?.name ?? key,
        }))
        .sort((a, b) => collator.compare(a.value, b.value))
    },
    [
      modelData?.processing,
      modelData?.inputs_processing,
      modelData?.categories,
      inputContraintsData,
      parameterList,
      labels?.processing
    ]
  )

  const processingDropdownOptionsCategorical = useCallback(
    (category) => {
      let processingNames = modelData?.processing
        ? Object.keys(modelData?.processing)
        : modelData?.inputs_processing
          ? modelData?.inputs_processing
          : []
      const collator = new Intl.Collator([], { numeric: false })

      return Object.entries(inputContraintsData || {})
        ?.filter(([key, value]: any) => !categorialInputList?.map((row: any) => row?.parameter).includes(key) && Array.isArray(value))
        .filter(([key]: any) => processingNames?.includes(key))
        .filter(([key]: any) => modelData?.categories?.['processing']?.[key]?.includes(category)
        )
        .map(([key, value]: any) => ({
          value: key,
          label: labels?.processing?.[key]?.name ?? labels?.processing?.[key]?.name ?? key,
        }))
        .sort((a, b) => collator.compare(a.value, b.value))
    },
    [
      modelData?.processing,
      modelData?.inputs_processing,
      modelData?.categories,
      inputContraintsData,
      categorialInputList,
      labels?.processing
    ]
  )

  const catwiseParameterConstraintsIngredients = useMemo(() => {
    return catwiseParameterList
      ?.find((typeObj: any) => typeObj.parameter_type === 'ingredients')
      ?.categories?.filter((catObj: any) => !catObj.exclude)
  }, [catwiseParameterList])

  // const catwiseParameterConstraintsProcessing = useMemo(() => {
  //   return catwiseParameterList?.find((typeObj: any) => typeObj.parameter_type === "processing")
  //     ?.categories?.filter((catObj: any) => !catObj.exclude)
  // }, [catwiseParameterList])

  const catergoriesWiseIngredients = catwiseParameterConstraintsIngredients?.reduce((acc: any, curr: any) => {
    if (!!curr?.category?.length) {
      return {
        ...acc,
        [curr.category]: Object.keys(inputContraintsData || {}).reduce((result: string[], currIng) => {
          if (modelData?.categories?.['ingredients']?.[currIng]?.includes(curr.category)) {
            if (!result.includes(currIng)) {
              result.push(currIng)
            }
          }
          return result
        }, [])
      }
    }
    return acc
  }, {})

  const paramterListDropDown = useCallback(
    (index: any, parameter: string, type: string, subtype: string, category: string, catIndex: number, error_parameter: string) => {
      const errorMessage = error_parameter
      return (

        <Row
          style={{
            alignItems: 'center',
            width: '100%',
            padding: 0,
            justifyContent: 'space-between',
            gap: 8,
            flexWrap: 'nowrap'
          }}
        >
          <Tooltip title={t('common.remove')}>
            <StyledDeleteIcon
              style={{
                fontSize: antdTheme.fontSizeHeading4,
                verticalAlign: 'baseline',
                outline: 'none'
              }}
              onClick={() => removeRow(index, subtype, category, parameter)}
            />
          </Tooltip>
          <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS / 2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display: 'flex', flexDirection: 'column', gap: '0rem', alignItems: 'flex-start', flexGrow: 1 }}>

            <Select
              value={modelData?.display_names?.[type]?.[parameter]}
              showSearch
              placeholder={t('aiEngine.selectInputs')}
              optionFilterProp="children"
              {...errorMessage && { status: "error" }}
              filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}
              style={{
                textAlign: 'left',
                flexGrow: 1,
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                verticalAlign: 'bottom'
              }}
              notFoundContent={
                <Space style={{ background: 'whitesmoke', width: '100%', padding: '0px 10px' }}>
                  <Text>{t('common.noSuchParameter')}</Text>
                </Space>
              }
              dropdownRender={(menu) => {
                return (
                  <>
                    {type === 'ingredients' && (
                      <Checkbox
                        checked={selectAllIngredientsInCategory?.[subtype]?.[category]?.length === catergoriesWiseIngredients?.[category]?.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const categoriesData = ingredientsDropdownOptionsNumerical(category)
                            if (!!categoriesData?.length) {
                              const filteredKeys = categoriesData.reduce((acc: string[], curr) => {
                                if (!acc.includes(curr.value)) {
                                  acc.push(curr.value)
                                }
                                return acc
                              }, [])

                              setSelectAllIngredientsInCategory((prev: any) => {
                                return {
                                  ...prev,
                                  [currentSelectedStage]: {
                                    ...prev[currentSelectedStage],
                                    [subtype]: {
                                      ...prev[currentSelectedStage]?.[subtype],
                                      [category]: [...new Set([...(prev[currentSelectedStage]?.[subtype]?.[category] ?? []), ...filteredKeys])]
                                    }
                                  }
                                }
                              })

                              const newParametersData = filteredKeys.reduce((acc: any[], curr, index) => {
                                acc.push({
                                  catIndex: catIndex,
                                  category,
                                  parameter: curr,
                                  exclude: false,
                                  include: false,
                                  min: null,
                                  max: null,
                                  type
                                })
                                return acc
                              }, [])

                              setParameterList((prevState: any) => {
                                const newState = JSON.parse(JSON.stringify(prevState))
                                const updatedList = [...(newState?.[currentSelectedStage] ?? []), ...newParametersData]
                                newState[currentSelectedStage] = updatedList.filter((inputFeature) => !!inputFeature.parameter.length)
                                return newState
                              })
                            }
                          } else {
                            setSelectAllIngredientsInCategory((prev: any) => {
                              return {
                                ...prev,
                                [currentSelectedStage]: {
                                  ...prev[currentSelectedStage],
                                  [subtype]: {
                                    ...prev[currentSelectedStage]?.[subtype],
                                    [category]: []
                                  }
                                }
                              }
                            })
                            setParameterList((prevState: any) => {
                              const newState = JSON.parse(JSON.stringify(prevState))
                              newState[currentSelectedStage] = newState[currentSelectedStage].filter((res: any) => res.category !== category)
                              return { ...newState }
                            })
                          }
                        }}
                        style={{ padding: '0px 10px' }}
                      >
                        {t('common.selectAll')}
                      </Checkbox>
                    )}
                    <Divider style={{ margin: '0.25rem 0rem' }} />
                    {menu}
                  </>
                )
              }}
              options={
                type === 'ingredients'
                  ? subtype === 'numerical'
                    ? ingredientsDropdownOptionsNumerical(category)
                    : ingredientsDropdownOptionsCategorical(category)
                  : type === 'processing'
                    ? subtype === 'numerical'
                      ? processingDropdownOptionsNumerical(category)
                      : processingDropdownOptionsCategorical(category)
                    : []
              }
              onChange={(e: any) => {
                if (subtype === 'categorical') {
                  setCategorialInputList((prevState: any) => {
                    const newState = JSON.parse(JSON.stringify(prevState))
                    newState[currentSelectedStage]?.forEach((res: any, parameterIndex: any) => {
                      if (index === parameterIndex) {
                        res.parameter = e
                        res.data = []
                        delete res?.error_parameter
                      }
                    })
                    return { ...newState }
                  })
                }
                if (subtype === 'numerical') {
                  setParameterList((prevState: any) => {
                    const newState = JSON.parse(JSON.stringify(prevState))
                    newState[currentSelectedStage]?.forEach((res: any, parameterIndex: any) => {
                      if (index === parameterIndex) {
                        res.parameter = e
                        res.exclude = exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked ?? false
                        res.include = false
                        res.min = exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked ? 0 : null
                        res.max = exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked ? 0 : null
                        delete res?.error_parameter
                      }
                    })
                    return { ...newState }
                  })
                  if (exludeAllParameterConstraints?.numerical_parameter?.[category]?.isChecked) {
                    setExludeAllParameterConstraints((prevState: any) => {
                      const newState = JSON.parse(JSON.stringify(prevState))
                      newState[currentSelectedStage] = {
                        ...newState[currentSelectedStage],
                        numerical_parameter: {
                          ...newState[currentSelectedStage]?.numerical_parameter,
                          [category]: {
                            ...newState[currentSelectedStage]?.numerical_parameter?.[category],
                            data: [...new Set([...(newState[currentSelectedStage]?.numerical_parameter?.[category]?.data ?? []), e])]
                          },
                        }
                      }
                      return newState
                    }, [])
                  }

                  setSelectAllIngredientsInCategory((prev: any) => {
                    return {
                      ...prev,
                      [currentSelectedStage]: {
                        ...prev[currentSelectedStage],
                        [subtype]: {
                          ...prev[currentSelectedStage]?.[subtype],
                          [category]: [...new Set([...(prev?.[currentSelectedStage]?.[category] ?? []), e])]
                        }
                      }
                    }
                  })
                }
                setUnsavedChanges(true)
              }}
            />

            <ErrorText text={errorMessage ? errorMessage : ''} />
          </div>
        </Row>

      )
    },
    [
      t,
      modelData?.display_names,
      ingredientsDropdownOptionsNumerical,
      ingredientsDropdownOptionsCategorical,
      processingDropdownOptionsNumerical,
      processingDropdownOptionsCategorical,
      removeRow,
      setUnsavedChanges,
      setCategorialInputList,
      currentSelectedStage,
      setParameterList,
      selectAllIngredientsInCategory,
      catergoriesWiseIngredients,
      setSelectAllIngredientsInCategory,
      exludeAllParameterConstraints?.numerical_parameter,
      setExludeAllParameterConstraints,
    ]
  )

  const getConstraintMode = useCallback(
    (catObj: any) => {
      if (catObj.include) {
        return INCLUSION_MODES[1].value
      }

      if (catObj.exclude) {
        return INCLUSION_MODES[2].value
      }

      return INCLUSION_MODES[0].value
    },
    [INCLUSION_MODES]
  )

  const excludeCategory = useCallback(
    (index: any, type, catObj) => {
      return (
        <Row justify="center" style={{ width: '100%', padding: 16 }}>
          <Select
            value={getConstraintMode(catObj)}
            showSearch
            placeholder={t('aiEngine.selectInputs')}
            optionFilterProp="children"
            filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}
            style={{
              textAlign: 'left',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              verticalAlign: 'bottom'
            }}
            notFoundContent={
              <Space style={{ background: 'whitesmoke', width: '100%', padding: '0px 10px' }}>
                <Text>{t('common.noSuchParameter')}</Text>
              </Space>
            }
            options={INCLUSION_MODES.map((mode) => ({
              ...mode,
              ...(mode.value === INCLUSION_MODES[2].value && EXCEPTIONS.mustIncludes.categories.includes(catObj.category) && { disabled: true })
            }))}
            onChange={(value: string) => {
              setCatwiseParameterList((prevState: any) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage] = prevState[currentSelectedStage].map((typeObj: any) => {
                  if (typeObj.parameter_type === type) {
                    const newTypeObj = {
                      ...typeObj,
                      categories: typeObj.categories.map((_catObj: any) => {
                        if (_catObj.category === catObj.category && index === _catObj.catIndex) {
                          if (value === INCLUSION_MODES[0].value) {
                            return {
                              ..._catObj,
                              include: false,
                              exclude: false,
                              min: null,
                              max: null,
                              sum_of_category_quantity: {
                                min: null,
                                max: null
                              },
                              count_of_category_items: { min: null, max: null }
                            }
                          } else {
                            return value === INCLUSION_MODES[2].value
                              ? {
                                ..._catObj,
                                include: false,
                                exclude: false,
                                [value]: true,
                                min: null,
                                max: null,
                                sum_of_category_quantity: {
                                  min: null,
                                  max: null
                                },
                                count_of_category_items: {
                                  min: null,
                                  max: null
                                }
                              }
                              : {
                                ..._catObj,
                                include: false,
                                exclude: false,
                                [value]: true,
                                min: null,
                                max: null,
                                sum_of_category_quantity: {
                                  min: null,
                                  max: null
                                },
                                count_of_category_items: {
                                  min: null,
                                  max: null
                                }
                              }
                          }
                        }
                        return _catObj
                      })
                    }
                    return newTypeObj
                  }
                  return typeObj
                })
                return newState
              })
              setCatConstraintsList((constraints: any) => {
                const newConstraints = JSON.parse(JSON.stringify(constraints))
                newConstraints[currentSelectedStage] = constraints[currentSelectedStage].map((constraint: any) => {
                  if (constraint.catIndex === index && constraint.type === type) {
                    const newConstraint = {
                      ...constraint,
                      min: null,
                      max: null
                    }
                    return newConstraint
                  }
                  return constraint
                })
                return { ...newConstraints }
              })

              if (type === 'ingredients') {
                setExludeAllCategoryConstraints((prev: any) => {
                  const newState = JSON.parse(JSON.stringify(prev))
                  const excludeCategoryData = newState[currentSelectedStage]?.[type] ?? []
                  newState[currentSelectedStage] = {
                    ...newState[currentSelectedStage],
                    [type]:
                      value !== 'exclude'
                        ? excludeCategoryData?.filter((category: string) => category !== catObj.category)
                        : value === 'exclude'
                          ? excludeCategoryData?.includes(catObj.category)
                            ? excludeCategoryData
                            : [...excludeCategoryData, catObj.category]
                          : excludeCategoryData
                  }
                  return newState
                })
              }
              // To remove items when excludes from parameter contraints
              if (value === 'exclude') {
                setParameterList((prevState: any) => {
                  const newState = JSON.parse(JSON.stringify(prevState))
                  newState[currentSelectedStage] = newState[currentSelectedStage].filter((parameter: any) => parameter.category !== catObj.category)
                  return newState
                })

                if (type === 'ingredients') {
                  const subtype = 'numerical'

                  setSelectAllIngredientsInCategory((prev: any) => {
                    return {
                      ...prev,
                      [currentSelectedStage]: {
                        ...prev[currentSelectedStage],
                        [subtype]: {
                          ...prev[currentSelectedStage]?.[subtype],
                          [catObj.category]: []
                        }
                      }
                    }
                  })
                }
              }
              setUnsavedChanges(true)
            }}
          />
        </Row>
      )
    },
    [
      getConstraintMode,
      t,
      setCatwiseParameterList,
      setCatConstraintsList,
      setUnsavedChanges,
      currentSelectedStage,
      setExludeAllCategoryConstraints,
      setParameterList,
      setSelectAllIngredientsInCategory,
      INCLUSION_MODES
    ]
  )

  // Reseting last few parameter inclusion modes from must to can if they exceed 
  // the limit of max cat constraints that was just changed
  useEffect(() => {
    setParameterList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      catConstraintsList?.filter((catObj: any) => catObj.constraint === 'count_of_category_items')?.forEach((catObj: any) => {
        const acceptedMustInclude = catObj?.max
        let mustIncludeParams = 0
        newState?.[currentSelectedStage]?.forEach((parameter: any, index: any) => {
          if (parameter.category === catObj.category && parameter.include) {
            mustIncludeParams++

            if (mustIncludeParams > acceptedMustInclude) {
              newState[currentSelectedStage][index].include = false
            }
          }

        })
      })
      return newState
    })
  }, [catConstraintsList, currentSelectedStage, setParameterList])

  // Reseting first few parameter inclusion modes from exclude to can if they exceed 
  // the limit of min cat constraints that was just changed
  useEffect(() => {
    setParameterList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))

      const categoryChanged: string[] = []
      catConstraintsList?.filter((catObj: any) => catObj.constraint === 'count_of_category_items')?.forEach((catObj: any) => {
        let nonExcludedParams = newState?.[currentSelectedStage]?.filter((parameter: any) => parameter.category === catObj.category && !parameter.exclude)?.length
        const minNeededParams = catObj?.min || 1
        newState?.[currentSelectedStage]?.forEach((parameter: any, index: any) => {
          if (parameter.category === catObj.category) {
            if (nonExcludedParams < minNeededParams && parameter.exclude) {
              newState[currentSelectedStage][index].exclude = false
              newState[currentSelectedStage][index].include = false
              nonExcludedParams++
              categoryChanged.push(catObj.category)
            }
          }
        })
      })

      if ([...new Set(categoryChanged)].length > 0) {
        const data: { [key: string]: string[] } = [...new Set(categoryChanged)].reduce((acc: any, curr: any) => {
          return {
            ...acc,
            [curr]: {
              isChecked: false,
              data: []
            }
          }
        }, {})

        setExludeAllParameterConstraints((prev: any) => {
          const newState = JSON.parse(JSON.stringify(prev))
          newState[currentSelectedStage] = {
            ...newState[currentSelectedStage],
            numerical_parameter: {
              ...newState[currentSelectedStage]?.numerical_parameter,
              ...data
            }
          }
          return newState
        })
      }

      return newState
    })
  }, [catConstraintsList, currentSelectedStage, setExludeAllParameterConstraints, setParameterList])

  const disableMustInclude = useCallback(
    (category: string) => {
      const allowedMaxMustInclude = (catConstraintsList || [])?.find((catObj: any) => catObj.category === category && catObj.constraint === "count_of_category_items")?.max
      const mustIncludeParams = (parameterList || [])?.filter((parameter: any) => parameter.category === category && parameter.include)?.length

      return allowedMaxMustInclude === null ? false : mustIncludeParams >= allowedMaxMustInclude
    },
    [catConstraintsList, parameterList]
  )

  const disableExclude = useCallback(
    (category: string) => {
      const minNeededNotExcluded = (catConstraintsList || [])?.find((catObj: any) => catObj.category === category && catObj.constraint === "count_of_category_items")?.min || 0
      const nonExcludedParams = (parameterList || [])?.filter((parameter: any) => parameter.category === category && !parameter.exclude)?.length
      return nonExcludedParams <= minNeededNotExcluded
    },
    [catConstraintsList, parameterList]
  )

  const excludeIngredients = useCallback(
    (index: any, parObj: any) => {
      return (
        <Row justify="center" style={{
          padding: 10,
          paddingTop: 16,
          paddingBottom: 16
        }} >
          <Select
            value={getConstraintMode(parObj)}
            showSearch
            placeholder={t('aiEngine.selectInputs')}
            optionFilterProp="children"
            filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}
            style={{
              textAlign: 'left',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              verticalAlign: 'bottom'
            }}
            notFoundContent={
              <Space style={{ background: 'whitesmoke', width: '100%', padding: '0px 10px' }}>
                <Text>{t('common.noSuchParameter')}</Text>
              </Space>
            }
            options={INCLUSION_MODES.map((mode) => ({
              ...mode,
              ...(mode.value === INCLUSION_MODES[2].value
                && (EXCEPTIONS.mustIncludes.parameters.includes(parObj.parameter)
                  || disableExclude(parObj.category))
                && { disabled: true }),
              ...(mode.value === INCLUSION_MODES[1].value && disableMustInclude(parObj.category) && { disabled: true })
            }))}
            onChange={(value: any) => {
              setParameterList((prevState: any) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage] = prevState[currentSelectedStage].map((res: any, parameterIndex: any) => {
                  if (index === parameterIndex) {
                    if (value === INCLUSION_MODES[0].value) {
                      return {
                        ...res,
                        include: false,
                        exclude: false,
                        min: null,
                        max: null
                      }
                    } else {
                      return value === INCLUSION_MODES[2].value
                        ? {
                          ...res,
                          include: false,
                          exclude: false,
                          [value]: true,
                          min: 0,
                          max: 0
                        }
                        : {
                          ...res,
                          include: false,
                          exclude: false,
                          [value]: true,
                          min: null,
                          max: null
                        }
                    }
                  }
                  return res
                })
                return newState
              })
              if (parObj.type === 'ingredients') {
                if (value === 'exclude') {
                  setExludeAllParameterConstraints((prevState: any) => {
                    const newState = JSON.parse(JSON.stringify(prevState))

                    newState[currentSelectedStage] = {
                      ...newState[currentSelectedStage],
                      numerical_parameter: {
                        ...newState[currentSelectedStage]?.numerical_parameter,
                        [parObj.category]: {
                          ...newState[currentSelectedStage]?.numerical_parameter?.[parObj.category],
                          data: [
                            ...new Set([...(newState[currentSelectedStage]?.numerical_parameter?.[parObj.category]?.data ?? []), parObj.parameter])
                          ]
                        },
                      }
                    }
                    return newState
                  }, [])
                } else {
                  setExludeAllParameterConstraints((prevState: any) => {
                    const newState = JSON.parse(JSON.stringify(prevState))
                    newState[currentSelectedStage] = {
                      ...newState[currentSelectedStage],
                      numerical_parameter: {
                        ...newState[currentSelectedStage]?.numerical_parameter,
                        [parObj.category]: {
                          ...newState[currentSelectedStage]?.numerical_parameter?.[parObj.category],
                          data: (newState[currentSelectedStage]?.numerical_parameter?.[parObj.category]?.data ?? [])?.filter(
                            (categoryItem: string) => categoryItem !== parObj.parameter
                          )
                        },
                      }
                    }
                    return newState
                  }, [])
                }
              }
            }}
          />
        </Row>
      )
    },
    [getConstraintMode, t, INCLUSION_MODES, disableExclude, disableMustInclude, setParameterList, currentSelectedStage, setExludeAllParameterConstraints]
  )

  const categorialInputListDropDown = useCallback(
    (index: any, parameter: any) => {
      const isCategorical = Array.isArray(modelData?.inputs_range?.[parameter] ?? inputContraintsData?.[parameter])
      return (
        <Select
          style={{ minWidth: 250, width: '100%' }}
          value={categorialInputList?.[index]?.data}
          mode="multiple"
          onChange={(e: any) => {
            setCategorialInputList((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState))
              newState[currentSelectedStage][index].data = e
              return { ...newState }
            })
            setUnsavedChanges(true)
          }}
        >
          {isCategorical &&
            (modelData?.inputs_range?.[parameter] ?? inputContraintsData?.[parameter])?.map((res: any) => (
              <Option value={res}>{typeof res === 'number' ? res : res?.[0]?.toUpperCase() + res.slice(1)}</Option>
            ))}
        </Select>
      )
    },
    [categorialInputList, currentSelectedStage, inputContraintsData, modelData?.inputs_range, setCategorialInputList, setUnsavedChanges]
  )

  const minMaxInputComponentCategoryConstraint = useCallback(
    (type, categoryObj, constraintIndex) => {

      const errorMessage = catConstraintsList?.[constraintIndex]?.[`error_${type[1]}`]

      const constraintType = catConstraintsList?.[constraintIndex]?.constraint

      return (
        <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS / 2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display: 'flex', flexDirection: 'column', gap: '0rem', alignItems: 'flex-start' }}>
          <InputNumber
            formatter={
              constraintType === "sum_of_category_quantity" ? formatter : (value: any) => (value)?.replace(/\D/g, '')
            }
            parser={
              constraintType === "sum_of_category_quantity" ? parser : (value: any) => (value)?.replace(/[^0-9]/g, '')
            }
            min={0}
            step={1}
            onStep={(value) => Math.max(0, value as number)}
            disabled={!!categoryObj?.exclude ? true : false}
            className="min__max__input"
            value={catConstraintsList?.[constraintIndex]?.[type[1]] === null ? '' : catConstraintsList?.[constraintIndex]?.[type[1]]}
            onChange={(e: any) => {
              const strValue = e
              const newCatConstraintList: any = [...catConstraintsList]
              if (type[0] === 'category_constraint') {
                newCatConstraintList.forEach((p: any, idx: any, pArr: any) => {
                  if (idx === constraintIndex) {
                    if (type[1] === 'min') {
                      pArr[idx] = {
                        ...pArr[idx],
                        min: strValue !== '' ? strValue : null
                      }
                      delete newCatConstraintList[idx][`error_${type[1]}`]
                    } else if (type[1] === 'max') {
                      pArr[idx] = {
                        ...pArr[idx],
                        max: strValue !== '' ? strValue : null
                      }
                      delete newCatConstraintList[idx][`error_${type[1]}`]
                    }
                  }
                  return p
                })
              }
              setCatConstraintsList((prevState: any) => ({
                ...prevState,
                [currentSelectedStage]: newCatConstraintList
              }))
            }}
            onBlur={(e) => {
              const strValue = e.target.value
              const convertedValue = convertValue(e.target.value)
              const newCatConstraintList: any = [...catConstraintsList]

              if (type[0] === 'category_constraint') {
                if (strValue === '') {
                  newCatConstraintList[constraintIndex][type[1]] = null
                  setUnsavedChanges(true)
                  return
                }
                newCatConstraintList.forEach((p: any, idx: any, pArr: any) => {
                  if (idx === constraintIndex) {
                    if (isNaN(convertedValue)) {
                      newCatConstraintList[idx][type[1]] = null
                      newCatConstraintList[idx][`error_${type[1]}`] = t('aiEngine.pleaseEnterAValidNumber')
                      return message.error(t('aiEngine.pleaseEnterAValidNumber'))
                    }
                    if (type[1] === 'min') {
                      if (newCatConstraintList[idx]?.max !== null) {
                        if (newCatConstraintList[idx]?.max < convertedValue) {
                          newCatConstraintList[idx].min = null
                          newCatConstraintList[idx][`error_${type[1]}`] = t('aiEngine.minShouldBeLessThanMax')
                          return message.error(t('aiEngine.minShouldBeLessThanMax'))
                        }
                      }
                      pArr[idx] = {
                        ...pArr[idx],
                        min: strValue !== '' ? convertedValue : null
                      }
                      delete newCatConstraintList[idx][`error_${type[1]}`]
                    } else if (type[1] === 'max') {
                      if (newCatConstraintList[idx]?.min !== null) {
                        if (newCatConstraintList[idx]?.min > convertedValue) {
                          newCatConstraintList[idx].max = null
                          newCatConstraintList[idx][`error_${type[1]}`] = t('aiEngine.maxShouldBeGreaterThanMin')
                          return message.error(t('aiEngine.maxShouldBeGreaterThanMin'))
                        }
                      }
                      pArr[idx] = {
                        ...pArr[idx],
                        max: strValue !== '' ? convertedValue : null
                      }
                      delete newCatConstraintList[idx][`error_${type[1]}`]
                    }
                  }
                  return p
                })
              }
              setCatConstraintsList((prevState: any) => ({
                ...prevState,
                [currentSelectedStage]: newCatConstraintList
              }))
              setUnsavedChanges(true)
            }}
            key={`${type[0]}-${type[1]}-${constraintIndex}`}
            {...errorMessage && { status: 'error' }}
          />
          <ErrorText text={errorMessage ? errorMessage : ''} />
        </div>
      );
    },
    [formatter, parser, catConstraintsList, setCatConstraintsList, currentSelectedStage, convertValue, setUnsavedChanges, t]
  )

  const categoryConstaintsDatasheetData = useCallback(
    (categoryObj: any, parameter_type: any) => {
      return [
        [
          {
            value: t('common.category'),
            forceComponent: true,
            readOnly: true,
            width: 200,
            component: (
              <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                <Typography.Text strong>{t('common.category')}</Typography.Text>
              </Row>
            )
          },
          {
            value: t('aiEngine.inclusionMode'),
            component: (
              <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                <Typography.Text strong>{t('aiEngine.inclusionMode')}</Typography.Text>
              </Row>
            ),
            forceComponent: true,
            readOnly: true,
            width: 200
          },
          {
            value: `${t('common.min')} ${t('common.quantity')}`,
            readOnly: true,
            forceComponent: true,
            component: (
              <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                <Typography.Text strong>{`${t('common.min')} ${t('common.quantity')}`}</Typography.Text>
              </Row>
            ),
            width: 200,
          },
          {
            value: `${t('common.max')} ${t('common.quantity')}`,
            readOnly: true,
            forceComponent: true,
            component: (
              <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                <Typography.Text strong>{`${t('common.max')} ${t('common.quantity')}`}</Typography.Text>
              </Row>
            ),
            width: 200,
          },
          {
            value: `${t('common.min')} ${t('common.count')}`,
            readOnly: true,
            forceComponent: true,
            component: (
              <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                <Typography.Text strong>{`${t('common.min')} ${t('common.count')}`}</Typography.Text>
              </Row>
            ),
            width: 200,
          },
          {
            value: `${t('common.max')} ${t('common.count')}`,
            readOnly: true,
            forceComponent: true,
            component: (
              <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                <Typography.Text strong>{`${t('common.max')} ${t('common.count')}`}</Typography.Text>
              </Row>
            ),
            width: 200,
          }
        ],
        [
          {
            value: '',
            component: categoryListDropDown(categoryObj.catIndex, parameter_type, categoryObj.category),
            forceComponent: true
          },
          {
            value: String(categoryObj?.exclude),
            component: excludeCategory(categoryObj.catIndex, parameter_type, categoryObj),
            forceComponent: true,
            disableEvents: true
          },
          ...(catConstraintsList || [])
            ?.flatMap((constraintObj: any, index: any) => ({
              constraintObj,
              index
            }))
            .filter(({ constraintObj }: any) => {
              const flag =
                constraintObj.type === parameter_type &&
                constraintObj.category === categoryObj.category &&
                constraintObj.catIndex === categoryObj.catIndex
              return flag
            })
            .flatMap(({ constraintObj, index }: any) => {
              return [
                {
                  value: constraintObj.min,
                  type: ['category_constraint', 'min'],
                  constraintIndex: index,
                  readOnly: !!categoryObj?.exclude ? true : false,
                  component: minMaxInputComponentCategoryConstraint(['category_constraint', 'min'], categoryObj, index),
                  forceComponent: true,
                  disableEvents: true
                },
                {
                  value: constraintObj.max,
                  type: ['category_constraint', 'max'],
                  constraintIndex: index,
                  readOnly: !!categoryObj?.exclude ? true : false,
                  component: minMaxInputComponentCategoryConstraint(['category_constraint', 'max'], categoryObj, index),
                  forceComponent: true,
                  disableEvents: true
                }
              ]
            })
        ]
      ]
    },
    [t, categoryListDropDown, excludeCategory, catConstraintsList, minMaxInputComponentCategoryConstraint]
  )

  const categoryConstraintsIngredientsData = useMemo(() => {
    return (catwiseParameterList || [])
      ?.filter((typeObj: any) => typeObj.parameter_type === 'ingredients')
      ?.flatMap((typeObj: any) => {
        return typeObj?.categories
      })
      ?.map((categoryObj: any) => {
        return {
          categoryObj,
          data: categoryConstaintsDatasheetData(categoryObj, 'ingredients')
        }
      })
  }, [catwiseParameterList, categoryConstaintsDatasheetData])

  const categoryConstraintsProcessingData = useMemo(() => {
    return (catwiseParameterList || [])
      ?.filter((typeObj: any) => typeObj.parameter_type === 'processing')
      .flatMap((typeObj: any) => {
        return typeObj?.categories
      })
      ?.map((categoryObj: any) => {
        return {
          categoryObj,
          data: categoryConstaintsDatasheetData(categoryObj, 'processing')
        }
      })
  }, [catwiseParameterList, categoryConstaintsDatasheetData])

  useEffect(() => {
    setCatwiseParameterList((state: any) => {
      const newState = JSON.parse(JSON.stringify(state))
      newState[currentSelectedStage] = newState[currentSelectedStage]?.map((typeObj: any) => {
        const newTypeObj = {
          ...typeObj,
          categories: typeObj.categories.map((catObj: any, catIndex: number) => {
            const newCatObj = {
              ...catObj,
              ...(catConstraintsList || [])
                ?.filter((constraintObj: any) => constraintObj.type === typeObj.parameter_type && catIndex === constraintObj.catIndex)
                ?.reduce((acc: any, constraintObj: any) => {
                  const newConstraint = {
                    ...acc,
                    ...constraintObj,
                    ...{
                      [constraintObj.constraint]: {
                        min: constraintObj.min,
                        max: constraintObj.max
                      }
                    },
                    item_constraints: {
                      ...(categorialInputList || [])
                        ?.filter(({ category, catIndex, type }: any) => {
                          const flag = constraintObj.category === category && catIndex === constraintObj.catIndex && type === constraintObj.type
                          return flag
                        })
                        .reduce(
                          (acc: any, paramObj: any) => ({
                            ...acc,
                            ...(paramObj.parameter && !!paramObj?.data?.length ? { [paramObj.parameter]: paramObj.data } : {})
                          }),
                          {}
                        ),
                      ...(parameterList || [])
                        ?.filter(({ category, catIndex, type }: any) => {
                          const flag = constraintObj.category === category && catIndex === constraintObj.catIndex && type === constraintObj.type
                          return flag
                        })
                        .reduce(
                          (acc: any, paramObj: any) => ({
                            ...acc,
                            ...(paramObj.parameter
                              ? {
                                [paramObj.parameter]: {
                                  ...paramObj,
                                  range: {
                                    min: paramObj.min,
                                    max: paramObj.max
                                  },
                                  exclude: paramObj.exclude,
                                  include: paramObj.include
                                }
                              }
                              : {})
                          }),
                          {}
                        )
                    }
                  }

                  if (
                    (newConstraint['count_of_category_items']?.min !== null && newConstraint['count_of_category_items']?.min > 0)
                    || (newConstraint['count_of_category_items']?.max !== null && newConstraint['count_of_category_items']?.max > 0)) {
                    newConstraint.include = true
                    newConstraint.exclude = false
                  }
                  return newConstraint
                }, {})
            }
            return newCatObj
          })
        }

        return newTypeObj
      })
      return newState
    })
  }, [parameterList, catConstraintsList, setCatwiseParameterList, categorialInputList, currentSelectedStage])

  const minMaxInputComponentParameterConstraint = useCallback(
    (type, parObj, constraintIndex) => {

      const errorMessage = parameterList?.[constraintIndex]?.[`error_${type[1]}`]

      return (
        <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS / 2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display: 'flex', flexDirection: 'column', gap: '0rem', alignItems: 'flex-start' }}>
          <InputNumber
            disabled={!!parObj?.exclude ? true : false}
            formatter={formatter}
            parser={parser}
            className="min__max__input"
            value={parameterList?.[constraintIndex]?.[type[1]] === null ? '' : parameterList?.[constraintIndex]?.[type[1]]}
            onChange={(e: any) => {
              const strValue = e
              const newParameterList: any = [...parameterList]
              if (type[0] === 'parameter_constraint') {
                newParameterList.forEach((p: any, idx: any, pArr: any) => {
                  if (idx === constraintIndex) {
                    if (type[1] === 'min') {
                      pArr[idx] = {
                        ...pArr[idx],
                        min: strValue !== '' ? strValue : null
                      }
                      delete newParameterList[idx][`error_${type[1]}`]
                    } else if (type[1] === 'max') {
                      pArr[idx] = {
                        ...pArr[idx],
                        max: strValue !== '' ? strValue : null
                      }
                      delete newParameterList[idx][`error_${type[1]}`]
                    }
                  }
                  return p
                })
              }
              setParameterList((prevState: any) => ({
                ...prevState,
                [currentSelectedStage]: newParameterList
              }))
            }}
            onBlur={(e) => {
              const strValue = e.target.value
              const convertedValue = convertValue(e.target.value)
              const newParameterList: any = [...parameterList]
              if (type[0] === 'parameter_constraint') {
                if (strValue === '') {
                  newParameterList[constraintIndex][type[1]] = null
                  newParameterList[constraintIndex][`error_${type[1]}`] = t('aiEngine.minMaxValuesCannotBeBlank')
                  setUnsavedChanges(true)
                  return
                }
                newParameterList.forEach((p: any, idx: any, pArr: any) => {
                  if (idx === constraintIndex) {
                    if (isNaN(convertedValue)) {
                      newParameterList[idx][type[1]] = null
                      newParameterList[idx][`error_${type[1]}`] = t('aiEngine.pleaseEnterAValidNumber')
                      return message.error(t('aiEngine.pleaseEnterAValidNumber'))
                    }
                    if (type[1] === 'min') {
                      if (newParameterList[idx]?.max !== null) {
                        if (convertValue(newParameterList[idx]?.max) < convertedValue) {
                          newParameterList[idx].min = null
                          newParameterList[idx][`error_${type[1]}`] = t('aiEngine.minShouldBeLessThanMax')
                          return message.error(t('aiEngine.minShouldBeLessThanMax'))
                        }
                      }
                      pArr[idx] = {
                        ...pArr[idx],
                        min: strValue !== '' ? getEUValue(strValue) : null
                      }
                      delete newParameterList[idx][`error_${type[1]}`]
                    } else if (type[1] === 'max') {
                      if (newParameterList[idx]?.min !== null) {
                        if (convertValue(newParameterList[idx]?.min) > convertedValue) {
                          newParameterList[idx].max = null
                          newParameterList[idx][`error_${type[1]}`] = t('aiEngine.maxShouldBeGreaterThanMin')
                          return message.error(t('aiEngine.maxShouldBeGreaterThanMin'))
                        }
                      }
                      pArr[idx] = {
                        ...pArr[idx],
                        max: strValue !== '' ? getEUValue(strValue) : null
                      }
                      delete newParameterList[idx][`error_${type[1]}`]
                    }
                  }
                  return p
                })
              }
              setParameterList((prevState: any) => ({
                ...prevState,
                [currentSelectedStage]: newParameterList
              }))
              setUnsavedChanges(true)
            }}
            {...errorMessage && { status: 'error' }}
          />
          <ErrorText text={errorMessage ? errorMessage : ''} />
        </div>
      )
    },
    [formatter, parser, parameterList, setParameterList, currentSelectedStage, convertValue, setUnsavedChanges, t, getEUValue]
  )

  const nonExcludedCategories = useMemo(
    () =>
      !!catwiseParameterConstraintsIngredients?.length ? catwiseParameterConstraintsIngredients.map((catObj: any) => catObj?.category) ?? [] : [],
    [catwiseParameterConstraintsIngredients]
  )

  const ingredientsNumericalParameterConstraintsData = useCallback(
    (category: string) => {
      return [
        ...(parameterList || [])
          ?.map((parObj: any, index: any) => ({
            parObj,
            index
          }))
          .filter(({ parObj }: any) => parObj.type === 'ingredients')
          .filter(({ parObj }: any) => {
            const flag = parObj.category === category
            return flag
          })
          .flatMap(({ parObj, index }: any, _index: number) => [
            ...(!_index
              ? [
                [
                  {
                    value: t('common.ingredients'),
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                        <Typography.Text strong>{t('aiEngine.inputFeatures')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true,
                    readOnly: true
                  },
                  {
                    value: t('inputConstraints.optimalRange'),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text strong>{t('inputConstraints.optimalRange')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true
                  },
                  {
                    value: t('aiEngine.inclusionMode'),
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                        <Typography.Text strong>{t('aiEngine.inclusionMode')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true,
                    readOnly: true
                  },
                  {
                    value: t('common.min'),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text strong>{t('common.min')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true
                  },
                  {
                    value: t('common.max'),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text strong>{t('common.max')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true
                  }
                ]
              ]
              : []),
            [
              {
                value: index,
                component: paramterListDropDown(index, parObj.parameter, parObj.type, 'numerical', parObj.category, parObj?.catIndex, parObj?.error_parameter),
                forceComponent: true,
                disableEvents: true,
                className: 'dropdown-remove-cell',
                width: 400
              },
              {
                value: `${convertValue(modelData?.inputs_range?.[parObj?.parameter]?.min, 3, true) ??
                  convertValue(inputContraintsData?.[parObj?.parameter]?.min, 3, true) ??
                  ''
                  } - ${convertValue(modelData?.inputs_range?.[parObj?.parameter]?.max, 3, true) ??
                  convertValue(inputContraintsData?.[parObj?.parameter]?.max, 3, true) ??
                  ''
                  }`,
                readOnly: true
              },
              {
                value: index,
                component: excludeIngredients(index, parObj),
                forceComponent: true,
                disableEvents: true
              },
              {
                value: parObj?.min,
                readOnly: !!parObj?.exclude ? true : false,
                type: ['parameter_constraint', 'min'],
                constraintIndex: index,
                component: minMaxInputComponentParameterConstraint(['parameter_constraint', 'min'], parObj, index),
                forceComponent: true,
                disableEvents: true
              },
              {
                value: parObj?.max,
                readOnly: !!parObj?.exclude ? true : false,
                type: ['parameter_constraint', 'max'],
                constraintIndex: index,
                component: minMaxInputComponentParameterConstraint(['parameter_constraint', 'max'], parObj, index),
                forceComponent: true,
                disableEvents: true
              }
            ]
          ])
      ]
    },
    [
      excludeIngredients,
      inputContraintsData,
      minMaxInputComponentParameterConstraint,
      modelData?.inputs_range,
      parameterList,
      paramterListDropDown,
      t,
      convertValue
    ]
  )

  const ingredientsCategoricalParameterConstraintsData = useCallback(
    (category: string) => {
      return [
        ...(categorialInputList || [])
          ?.map((parObj: any, index: any) => ({
            parObj,
            index
          }))
          .filter(({ parObj }: any) => parObj.type === 'ingredients')
          .filter(({ parObj }: any) => {
            const flag = parObj.category === category
            return flag
          })
          .flatMap(({ parObj, index }: any, _index: number) => [
            ...(!_index
              ? [
                [
                  {
                    value: t('common.ingredients'),
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                        <Typography.Text strong>{t('aiEngine.inputFeatures')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true,
                    readOnly: true
                  },
                  {
                    value: t('common.value'),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text strong>{t('common.value')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true
                  }
                ]
              ]
              : []),
            [
              {
                value: index,
                component: paramterListDropDown(index, parObj.catIndex, parObj.type, 'categorical', parObj.category, parObj?.catIndex, parObj?.error_parameter),
                forceComponent: true,
                disableEvents: true,
                className: 'dropdown-remove-cell',
                width: 400
              },
              {
                value: parObj?.min,
                component: categorialInputListDropDown(index, parObj.parameter),
                forceComponent: true,
                readOnly: !!parObj?.exclude ? true : false,
                type: ['parameter_constraint', 'min'],
                constraintIndex: index
              }
            ]
          ])
      ]
    },
    [categorialInputList, categorialInputListDropDown, paramterListDropDown, t]
  )

  const processingNumericalParameterConstraintsData = useCallback(
    (category: string) => {
      return [
        ...(parameterList || [])
          ?.map((parObj: any, index: any) => ({
            parObj,
            index
          }))
          .filter(({ parObj }: any) => parObj.type === 'processing')
          .filter(({ parObj }: any) => {
            const flag = parObj.category === category
            return flag
          })
          .flatMap(({ parObj, index }: any, _index: number) => [
            ...(!_index
              ? [
                [
                  {
                    value: t('common.processing'),
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                        <Typography.Text strong>{t('aiEngine.inputFeatures')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true,
                    readOnly: true
                  },
                  {
                    value: t('inputConstraints.optimalRange'),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text strong>{t('inputConstraints.optimalRange')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true
                  },
                  {
                    value: t('aiEngine.inclusionMode'),
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                        <Typography.Text strong>{t('aiEngine.inclusionMode')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true,
                    readOnly: true
                  },
                  {
                    value: t('common.min'),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text strong>{t('common.min')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true
                  },
                  {
                    value: t('common.max'),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text strong>{t('common.max')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true
                  }
                ]
              ]
              : []),
            [
              {
                value: index,
                component: paramterListDropDown(index, parObj.parameter, parObj.type, 'numerical', parObj.category, parObj?.catIndex, parObj?.error_parameter),
                forceComponent: true,
                disableEvents: true,
                className: 'dropdown-remove-cell',
                width: 400
              },
              {
                value: `${convertValue(modelData?.inputs_range?.[parObj?.parameter]?.min, 3, true) ??
                  convertValue(inputContraintsData?.[parObj?.parameter]?.min, 3, true) ??
                  ''
                  } - ${convertValue(modelData?.inputs_range?.[parObj?.parameter]?.max, 3, true) ??
                  convertValue(inputContraintsData?.[parObj?.parameter]?.max, 3, true) ??
                  ''
                  }`,
                readOnly: true
              },
              {
                value: index,
                component: excludeIngredients(index, parObj),
                forceComponent: true,
                disableEvents: true
              },
              {
                value: parObj?.min,
                readOnly: !!parObj?.exclude ? true : false,
                type: ['parameter_constraint', 'min'],
                constraintIndex: index,
                component: minMaxInputComponentParameterConstraint(['parameter_constraint', 'min'], parObj, index),
                forceComponent: true,
                disableEvents: true
              },
              {
                value: parObj?.max,
                readOnly: !!parObj?.exclude ? true : false,
                type: ['parameter_constraint', 'max'],
                constraintIndex: index,
                component: minMaxInputComponentParameterConstraint(['parameter_constraint', 'max'], parObj, index),
                forceComponent: true,
                disableEvents: true
              }
            ]
          ])
      ]
    },
    [
      excludeIngredients,
      inputContraintsData,
      minMaxInputComponentParameterConstraint,
      modelData?.inputs_range,
      parameterList,
      paramterListDropDown,
      t,
      convertValue
    ]
  )

  const categoriesList: { [key: string]: string[] } = useMemo(() => {
    return {
      ingredients: !!catwiseParameterList?.length
        ? catwiseParameterList?.reduce((acc: any, curr: any) => {
          if (curr?.parameter_type === 'ingredients') {
            const categories = curr.categories.map((categoryItem: any) => categoryItem.category)
            if (!!categories.length) {
              acc.push(...categories)
            }
          }
          return [...new Set(acc)]
        }, [])
        : []
    }
  }, [catwiseParameterList])

  const processingCategoricalParameterConstraintsData = useCallback(
    (category: string) => {
      return [
        ...(categorialInputList || [])
          ?.map((parObj: any, index: any) => ({
            parObj,
            index
          }))
          .filter(({ parObj }: any) => parObj.type === 'processing')
          .filter(({ parObj }: any) => {
            const flag = parObj.category === category
            return flag
          })
          .flatMap(({ parObj, index }: any, _index: number) => [
            ...(!_index
              ? [
                [
                  {
                    value: t('common.processing'),
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8, width: '100%' }}>
                        <Typography.Text strong>{t('aiEngine.inputFeatures')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true,
                    readOnly: true
                  },
                  {
                    value: t('common.value'),
                    readOnly: true,
                    component: (
                      <Row style={{ padding: 8, marginLeft: 8 }}>
                        <Typography.Text strong>{t('common.value')}</Typography.Text>
                      </Row>
                    ),
                    forceComponent: true
                  }
                ]
              ]
              : []),
            [
              {
                value: index,
                component: paramterListDropDown(index, parObj.catIndex, parObj.type, 'categorical', parObj.category, parObj?.catIndex, parObj?.error_parameter),
                forceComponent: true,
                disableEvents: true,
                className: 'dropdown-remove-cell',
                width: 400
              },
              {
                value: parObj?.min,
                component: categorialInputListDropDown(index, parObj.parameter),
                forceComponent: true,
                readOnly: !!parObj?.exclude ? true : false,
                type: ['parameter_constraint', 'min'],
                constraintIndex: index
              }
            ]
          ])
      ]
    },
    [categorialInputList, categorialInputListDropDown, paramterListDropDown, t]
  )

  const updateInclusionMode = (e: CheckboxChangeEvent, parameterType: string) => {
    setCatwiseParameterList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState[currentSelectedStage] = prevState[currentSelectedStage].map((typeObj: any) => {
        if (typeObj.parameter_type === parameterType) {
          const newTypeObj = {
            ...typeObj,
            categories: typeObj.categories.map((_catObj: any) => {
              return {
                ..._catObj,
                include: false,
                exclude: e.target.checked,
                min: null,
                max: null,
                sum_of_category_quantity: {
                  min: null,
                  max: null
                },
                count_of_category_items: {
                  min: null,
                  max: null
                }
              }
            })
          }
          return newTypeObj
        }
        return typeObj
      })
      return newState
    })

    setCatConstraintsList((constraints: any) => {
      const newConstraints = JSON.parse(JSON.stringify(constraints))
      newConstraints[currentSelectedStage] = constraints[currentSelectedStage].map((constraint: any) => {
        if (constraint.type === parameterType) {
          const newConstraint = {
            ...constraint,
            min: null,
            max: null
          }
          return newConstraint
        }
        return constraint
      })
      return { ...newConstraints }
    })
    setExludeAllCategoryConstraints((prev: any) => {
      const newState = JSON.parse(JSON.stringify(prev))
      newState[currentSelectedStage] = {
        ...newState[currentSelectedStage],
        [parameterType]: e.target.checked ? (!!categoriesList?.[parameterType]?.length ? categoriesList?.[parameterType] : []) : []
      }
      return newState
    })
    setUnsavedChanges(true)
  }

  const handleExcludeAllSelectionInNumericalParameters = (selectedCategory: string, isChecked: boolean) => {
    setParameterList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState[currentSelectedStage] = newState[currentSelectedStage].map((parameter: any) => {
        if (selectedCategory === parameter.category) {
          return {
            ...parameter,
            include: false,
            exclude: isChecked,
            min: null,
            max: null,
            sum_of_category_quantity: {
              min: null,
              max: null
            },
            count_of_category_items: {
              min: null,
              max: null
            }
          }
        } else {
          return parameter
        }
      })
      return newState
    })

    const allUniqueCategories: string[] = [...new Set(selectedCategory)]

    const data: { [key: string]: string[] } = allUniqueCategories.reduce((acc: any, curr: any) => {
      return {
        ...acc,
        [curr]: {
          isChecked,
          data: isChecked
            ? parameterList.filter((params: any) => (params.category === curr)).map((params: any) => params.parameter)
            : []
        }
      }
    }, {})

    setExludeAllParameterConstraints((prev: any) => {
      const newState = JSON.parse(JSON.stringify(prev))
      newState[currentSelectedStage] = {
        ...newState[currentSelectedStage],
        numerical_parameter: {
          ...newState[currentSelectedStage]?.numerical_parameter,
          ...data
        }
      }
      return newState
    })
    setUnsavedChanges(true)
  }

  return (
    <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
      {/* Ingredients */}
      <Collapse size="small" style={{ width: '100%' }} collapsible={categoryConstraintsIngredientsData?.length ? undefined : 'disabled'} defaultActiveKey={[]}>
        <Collapse.Panel
          key="category-constraints-ingredients"
          header={`${t('common.ingredients')} (${categoryConstraintsIngredientsData.length ? categoryConstraintsIngredientsData.length : t('common.noData')
            })`}
        >
          {!!categoryConstraintsIngredientsData?.length && (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem 0rem', gap: 10, paddingBottom: '2rem' }}>
              {
                <div style={{ display: 'flex', gap: 0, alignItems: 'center', height: '2rem' }}>
                  <Checkbox
                    checked={isCrossCategorical?.[currentSelectedStage]}
                    onChange={(e) => {
                      setIsCrossCategorical((prev: any) => {
                        const newState = JSON.parse(JSON.stringify(prev))
                        newState[currentSelectedStage] = e.target.checked
                        return newState
                      })
                      if (e.target.checked) setIsCrossCategoricalModalVisible(true)
                      setUnsavedChanges(true)
                    }}
                  >
                    {t('aiEngine.crossCategorical')}
                  </Checkbox>
                  {isCrossCategorical?.[currentSelectedStage] && (
                    <StyledButton size="small" type="link" onClick={() => setIsCrossCategoricalModalVisible(true)} style={{ outline: 'none' }}>
                      {t('common.show')}
                    </StyledButton>
                  )}
                </div>
              }

              <Space>
                {/* <Tooltip title={t('aiEngine.useDefaultConstraintForCategories')}>
                  <Switch
                    checked={isCategoricalConstraintsUseDefault?.[currentSelectedStage]}
                    onChange={(e: any) => {
                      setIsCategoricalConstraintsUseDefault((prev: any) => {
                        const newState = JSON.parse(JSON.stringify(prev))
                        newState[currentSelectedStage] = e
                        return newState
                      })
                      setUnsavedChanges(true)
                    }}
                    checkedChildren={t('aiEngine.useDefault')}
                    unCheckedChildren={t('aiEngine.useDefault')}
                    style={{ outline: 'none' }}
                  />
                </Tooltip> */}

                <Checkbox
                  checked={exludeAllCategoryConstraints?.['ingredients']?.length === categoriesList?.['ingredients']?.length}
                  onChange={(e) => updateInclusionMode(e, 'ingredients')}
                >
                  {t('aiEngine.excludeAll')}
                </Checkbox>
              </Space>
            </div>
          )}
          <Space direction="vertical" size={'large'}>
            {categoryConstraintsIngredientsData?.map(({ data, categoryObj }: any, index: number) => {
              return (
                <IndividualCategoryConstraintsTableIngredients
                  key={index}
                  data={data}
                  categoryObj={categoryObj}
                  addRow={addRow}
                  parameterList={parameterList}
                  categorialInputList={categorialInputList}
                  nonExcludedCategories={nonExcludedCategories}
                  ingredientsDropdownOptionsNumerical={ingredientsDropdownOptionsNumerical}
                  ingredientsDropdownOptionsCategorical={ingredientsDropdownOptionsCategorical}
                  ingredientsNumericalParameterConstraintsData={ingredientsNumericalParameterConstraintsData}
                  ingredientsCategoricalParameterConstraintsData={ingredientsCategoricalParameterConstraintsData}
                  exludeAllParameterConstraints={exludeAllParameterConstraints}
                  handleExcludeAllSelectionInNumericalParameters={handleExcludeAllSelectionInNumericalParameters}
                  modelData={modelData}
                />
              )
            })}
          </Space>
        </Collapse.Panel>
      </Collapse>

      {/* Processing */}
      <Collapse size="small" style={{ width: '100%' }} collapsible={categoryConstraintsProcessingData.length ? undefined : 'disabled'} defaultActiveKey={[]}>
        <Collapse.Panel
          key="category-constraints-processing"
          header={`${t('common.processing')} (${categoryConstraintsProcessingData.length ? categoryConstraintsProcessingData.length : t('common.noData')
            })`}
        >
          <Space direction="vertical" size={'large'}>
            {categoryConstraintsProcessingData?.map(({ data, categoryObj }: any, index: number) => {
              return (
                <IndividualCategoryConstraintsTableProcessing
                  key={index}
                  data={data}
                  categoryObj={categoryObj}
                  addRow={addRow}
                  parameterList={parameterList}
                  categorialInputList={categorialInputList}
                  processingDropdownOptionsNumerical={processingDropdownOptionsNumerical}
                  processingDropdownOptionsCategorical={processingDropdownOptionsCategorical}
                  processingNumericalParameterConstraintsData={processingNumericalParameterConstraintsData}
                  processingCategoricalParameterConstraintsData={processingCategoricalParameterConstraintsData}
                  modelData={modelData}
                />
              )
            })}
          </Space>
        </Collapse.Panel>
      </Collapse>
    </Space>
  )
}

const IndividualCategoryConstraintsTableIngredients = ({
  data,
  categoryObj,
  addRow,
  parameterList,
  categorialInputList,
  nonExcludedCategories,
  ingredientsDropdownOptionsNumerical,
  ingredientsDropdownOptionsCategorical,
  ingredientsNumericalParameterConstraintsData,
  ingredientsCategoricalParameterConstraintsData,
  exludeAllParameterConstraints,
  handleExcludeAllSelectionInNumericalParameters,
  modelData
}: {
  data: any
  categoryObj: any
  addRow: any
  parameterList: any
  categorialInputList: any
  nonExcludedCategories: any
  ingredientsDropdownOptionsNumerical: any
  ingredientsDropdownOptionsCategorical: any
  ingredientsNumericalParameterConstraintsData: any
  ingredientsCategoricalParameterConstraintsData: any
  exludeAllParameterConstraints: any
  handleExcludeAllSelectionInNumericalParameters: any,
  modelData: any
}) => {
  const [t] = useTranslate()
  const isOpenRef = useRef(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpenRef.current)
  }, [])


  const categoricalParameters = useMemo(() => {
    return categorialInputList
      ?.filter((parObj: any) => parObj?.type === 'ingredients')
      ?.filter((parObj: any) => {
        const flag = parObj.category === categoryObj.category
        return flag
      })
  }, [categoryObj.category, categorialInputList])

  const isCategoricalParametersPresent = useMemo(() => {
    return !!categoricalParameters?.length
  }, [categoricalParameters?.length])

  const numericalParameters = useMemo(() => {
    return parameterList
      ?.filter((parObj: any) => parObj?.type === 'ingredients')
      ?.filter((parObj: any) => nonExcludedCategories.includes(parObj.category))
      ?.filter((parObj: any) => {
        const flag = parObj.category === categoryObj.category
        return flag
      })
  }, [categoryObj.category, nonExcludedCategories, parameterList])

  const isNumericalParametersPresent = useMemo(() => {
    return !!numericalParameters.length
  }, [numericalParameters.length])

  const canAddCategoricalParams = useMemo(() => {
    return categoricalParameters?.length < modelData?.items_per_category?.['ingredients']?.[categoryObj.category]?.length
  }, [categoricalParameters?.length, categoryObj.category, modelData?.items_per_category])

  const canAddNumericalParams = useMemo(() => {
    return numericalParameters?.length < modelData?.items_per_category?.['ingredients']?.[categoryObj.category]?.length
  }, [numericalParameters?.length, categoryObj.category, modelData?.items_per_category])

  const isExcluded = useMemo(() => {
    return categoryObj.exclude
  }, [categoryObj.exclude])

  const isMustInclude = useMemo(() => {
    return categoryObj.include
  }, [categoryObj.include])

  const panelKeyName = useMemo(() => {
    return `${categoryObj.category}-parameter-constraints`
  }, [categoryObj.category])

  useEffect(() => {
    if (isExcluded) {
      setIsOpen(false)
      isOpenRef.current = false
    }
  }, [isExcluded])

  useEffect(() => {
    if (isNumericalParametersPresent || isCategoricalParametersPresent) {
      setIsOpen(true)
      isOpenRef.current = true
    }
  }, [isCategoricalParametersPresent, isNumericalParametersPresent])

  return (
    <Space direction="vertical" size={'large'} style={{ gap: 0 }} className="catwise-individual-category-container">
      <IDataSheet data={data} valueRenderer={(cell) => cell.value} className="catwise-category-constraints-tab-table" />
      <Collapse size="small" onChange={() => {
        setIsOpen(prev => {
          isOpenRef.current = !prev
          return !prev
        })
      }} bordered={false} style={{ width: '100%' }}
        collapsible={isExcluded ? "disabled" : "header"}
        defaultActiveKey={
          isNumericalParametersPresent || isCategoricalParametersPresent ? [panelKeyName] : []
        }
        activeKey={isOpen ? [panelKeyName] : []}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        destroyInactivePanel={true}
      >
        <Collapse.Panel
          key={panelKeyName}
          header={
            <Typography.Text strong disabled={isExcluded} >
              {t('common.parameterConstraints')}
            </Typography.Text>
          }
          style={{
            borderRadius: '0px 0px 6px 6px',
            border: 'none',
            backgroundColor: '#FAFAFA'
          }}
        >
          <Space
            style={{ background: isOpen ? '#FAFAFA' : 'transparent', width: '100%' }}
            size={'middle'}
            direction="vertical"
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space size={'middle'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StyledButton
                  size="small"
                  onClick={() => addRow('ingredients', categoryObj.category, categoryObj.catIndex, 'numerical')}
                  icon={<PlusOutlined />}
                  type="link"
                  disabled={isExcluded || !canAddNumericalParams || ingredientsDropdownOptionsNumerical(categoryObj.category).length <= 0}
                  style={{ outline: 'none' }}
                >
                  {`${t('common.add')} ${t('common.numerical')} ${t('common.parameter')}`}
                </StyledButton>

                <StyledButton
                  size="small"
                  onClick={() => addRow('ingredients', categoryObj.category, categoryObj.catIndex, 'categorical')}
                  icon={<PlusOutlined />}
                  type="link"
                  disabled={isExcluded || !canAddCategoricalParams || ingredientsDropdownOptionsCategorical(categoryObj.category).length <= 0}
                  style={{ outline: 'none' }}
                >
                  {`${t('common.add')} ${t('common.categorical')} ${t('common.parameter')}`}
                </StyledButton>
              </Space>
              {!!isNumericalParametersPresent && (
                <StyledCard
                  title={t('common.numericalParameters')}
                  bodyStyle={{ padding: '0rem' }}
                  headStyle={{ padding: '0rem', border: 'none' }}
                  style={{ padding: '0rem 2rem' }}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem 0rem' }}>
                    {!!parameterList.filter(
                      (param: any) => (categoryObj.category === param.category) && param.parameter.length
                    )?.length && (
                        <Checkbox
                          checked={
                            !!exludeAllParameterConstraints?.numerical_parameter?.[categoryObj.category]?.data
                              ?.length && !!parameterList.length
                              ? exludeAllParameterConstraints?.numerical_parameter?.[categoryObj.category]?.data
                                ?.length ===
                              parameterList.filter((parameter: any) =>
                                categoryObj.category === parameter.category
                              )?.length ?? false
                              : false
                          }
                          onChange={(e) =>
                            handleExcludeAllSelectionInNumericalParameters(categoryObj.category, e.target.checked)
                          }
                          disabled={isMustInclude || isExcluded}
                        >
                          {t('aiEngine.excludeAll')}
                        </Checkbox>
                      )}
                  </div>
                  <IDataSheet
                    data={ingredientsNumericalParameterConstraintsData(categoryObj.category)}
                    valueRenderer={(cell) => cell.value}
                    className="catwise-parameter-constraints-tab-table"
                  />
                </StyledCard>
              )}
              {!!isCategoricalParametersPresent && (
                <StyledCard
                  title={t('common.categoricalParameters')}
                  bodyStyle={{ padding: '0rem' }}
                  headStyle={{ padding: '0rem', border: 'none' }}
                  style={{ padding: '0rem 2rem' }}
                >
                  <IDataSheet
                    data={ingredientsCategoricalParameterConstraintsData(categoryObj.category)}
                    valueRenderer={(cell) => cell.value}
                    className="catwise-parameter-constraints-tab-table"
                  />
                </StyledCard>
              )}
              {!isNumericalParametersPresent &&
                !isCategoricalParametersPresent && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Empty
                      description={
                        <Text>
                          {`${t('common.add')} ${t('common.numerical')} ${t('common.or')} ${t('common.categorical')} ${t('common.parameter')}`}
                        </Text>
                      }
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{ width: '100%' }}
                    />
                  </div>
                )}
            </Space>
          </Space>
        </Collapse.Panel>
      </Collapse>
    </Space>
  )
}

const IndividualCategoryConstraintsTableProcessing = ({
  data,
  categoryObj,
  addRow,
  parameterList,
  categorialInputList,
  processingDropdownOptionsNumerical,
  processingDropdownOptionsCategorical,
  processingNumericalParameterConstraintsData,
  processingCategoricalParameterConstraintsData,
  modelData
}: {
  data: any
  categoryObj: any
  addRow: any
  parameterList: any
  categorialInputList: any
  processingDropdownOptionsNumerical: any
  processingDropdownOptionsCategorical: any
  processingNumericalParameterConstraintsData: any
  processingCategoricalParameterConstraintsData: any
  modelData: any
}) => {
  const [t] = useTranslate()
  const isOpenRef = useRef(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpenRef.current)
  }, [])

  const numericalParameters = useMemo(() => {
    return parameterList
      ?.filter((parObj: any) => parObj?.type === 'processing')
      ?.filter((parObj: any) => {
        const flag = parObj.category === categoryObj.category
        return flag
      })
  }, [categoryObj.category, parameterList])

  const isNumericalParametersPresent = useMemo(() => {
    return !!numericalParameters.length
  }, [numericalParameters.length])

  const categoricalParameters = useMemo(() => {
    return categorialInputList
      ?.filter((parObj: any) => parObj?.type === 'processing')
      ?.filter((parObj: any) => {
        const flag = parObj.category === categoryObj.category
        return flag
      })
  }, [categoryObj.category, categorialInputList])

  const isCategoricalParametersPresent = useMemo(() => {
    return !!categoricalParameters?.length
  }, [categoricalParameters?.length])

  const canAddNumericalParams = useMemo(() => {
    return numericalParameters?.length < modelData?.items_per_category?.['processing']?.[categoryObj.category]?.length
  }, [numericalParameters?.length, categoryObj.category, modelData?.items_per_category])

  const canAddCategoricalParams = useMemo(() => {
    return categoricalParameters?.length < modelData?.items_per_category?.['processing']?.[categoryObj.category]?.length
  }, [categoricalParameters?.length, categoryObj.category, modelData?.items_per_category])


  const isExcluded = useMemo(() => {
    return categoryObj.exclude
  }, [categoryObj.exclude])

  const panelKeyName = useMemo(() => {
    return `${categoryObj.category}-parameter-constraints`
  }, [categoryObj.category])

  useEffect(() => {
    if (isExcluded) {
      setIsOpen(false)
      isOpenRef.current = false
    }
  }, [isExcluded])

  useEffect(() => {
    if (isNumericalParametersPresent || isCategoricalParametersPresent) {
      setIsOpen(true)
      isOpenRef.current = true
    }
  }, [isCategoricalParametersPresent, isNumericalParametersPresent])

  return (
    <Space direction="vertical" size={'large'} style={{ gap: 0 }} className="catwise-individual-category-container">
      <IDataSheet className="catwise-category-constraints-tab-table" data={data} valueRenderer={(cell) => cell.value} />
      <Collapse size="small" onChange={() => {
        setIsOpen(prev => {
          isOpenRef.current = !prev
          return !prev
        })
      }} bordered={false} style={{ width: '100%' }}
        collapsible={isExcluded ? "disabled" : "header"}
        defaultActiveKey={
          isNumericalParametersPresent || isCategoricalParametersPresent ? [panelKeyName] : []
        }
        activeKey={isOpen ? [panelKeyName] : []}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
        <Collapse.Panel
          key={panelKeyName}
          header={
            <Typography.Text strong disabled={isExcluded}>
              {t('common.parameterConstraints')}
            </Typography.Text>
          }
          style={{
            borderRadius: '0px 0px 6px 6px',
            border: 'none',
            backgroundColor: '#FAFAFA'
          }}
        >
          <Space direction="vertical" size={'middle'} style={{ width: '100%' }}>
            <Space size={'middle'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledButton
                size="small"
                onClick={() => addRow('processing', categoryObj.category, categoryObj.catIndex, 'numerical')}
                icon={<PlusOutlined />}
                type="link"
                disabled={isExcluded || !canAddNumericalParams || processingDropdownOptionsNumerical(categoryObj.category).length <= 0}
                style={{ outline: 'none' }}
              >
                {`${t('common.add')} ${t('common.numerical')} ${t('common.parameter')}`}
              </StyledButton>

              <StyledButton
                size="small"
                onClick={() => addRow('processing', categoryObj.category, categoryObj.catIndex, 'categorical')}
                icon={<PlusOutlined />}
                type="link"
                disabled={isExcluded || !canAddCategoricalParams || processingDropdownOptionsCategorical(categoryObj.category).length <= 0}
                style={{ outline: 'none' }}
              >
                {`${t('common.add')} ${t('common.categorical')} ${t('common.parameter')}`}
              </StyledButton>
            </Space>
            {!!isNumericalParametersPresent && (
              <StyledCard
                title={t('common.numericalParameters')}
                bodyStyle={{ padding: '0rem' }}
                headStyle={{ padding: '0rem', border: 'none' }}
                style={{ padding: '0rem 2rem' }}
              >
                <IDataSheet
                  data={processingNumericalParameterConstraintsData(categoryObj.category)}
                  valueRenderer={(cell) => cell.value}
                  className="catwise-parameter-constraints-tab-table"
                />
              </StyledCard>
            )}
            {!!isCategoricalParametersPresent && (
              <StyledCard
                title={t('common.categoricalParameters')}
                bodyStyle={{ padding: '0rem' }}
                headStyle={{ padding: '0rem', border: 'none' }}
                style={{ padding: '0rem 2rem' }}
              >
                <IDataSheet
                  data={processingCategoricalParameterConstraintsData(categoryObj.category)}
                  valueRenderer={(cell) => cell.value}
                  className="catwise-parameter-constraints-tab-table"
                />
              </StyledCard>
            )}
            {!isNumericalParametersPresent &&
              !isCategoricalParametersPresent && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Empty
                    description={
                      <Text>
                        {`${t('common.add')} ${t('common.numerical')} ${t('common.or')} ${t('common.categorical')} ${t('common.parameter')}`}
                      </Text>
                    }
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ width: '100%' }}
                  />
                </div>
              )}
          </Space>
        </Collapse.Panel>
      </Collapse>
    </Space>
  )
}

export const ErrorText = ({ text }: { text: string }) => {
  return (
    <Text type="danger" style={{
      fontSize: '0.6rem',
      height: '1rem',
      fontWeight: 600,
      paddingLeft: '0.5rem',
      maxWidth: '200px',
    }}
      ellipsis
    >
      {text}
    </Text>
  )
}

export const PADDING_TOP_FOR_DATASHEET_CELLS = 16